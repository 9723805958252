import { Heading, Paragraph, SubHeading, Caption, BulletList, ParagraphSlant, SectionWrapper, NumberedList, SubHeading2 } from "../styles/base";

export default function PrivacyPolicyText() {
  return (
    <>
      <SectionWrapper>
      <Caption>Effective Date: 03/07/2024</Caption>
      <Paragraph>This privacy policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application, Flowtient. By using Flowtient, you agree to the terms of this privacy policy.</Paragraph>

      <SubHeading id="whatinfo">1. What Information Do We Collect?</SubHeading>
<Paragraph>We may collect the following types of information:</Paragraph>      
<SubHeading2>Personal Information:</SubHeading2>
<BulletList> 
  <li> <strong>Email Address:</strong> When you contact us for support or provide feedback. </li>
</BulletList>

<SubHeading2>Usage Data:</SubHeading2>
<BulletList> 
  <li> <strong>App Usage:</strong> Information about your use of the app, including the features you use, the time spent on the app, and other related usage statistics.</li>
</BulletList>

<SubHeading2>Device Information:</SubHeading2>
<BulletList> 
  <li> <strong>Device Details:</strong> Information about your device, including device type, operating system, unique device identifiers, and mobile network information.</li>
</BulletList>

<SubHeading id="howuseinfo">2. How We Use Your Information</SubHeading>
  <Paragraph>We use the information we collect for the following purposes:</Paragraph>
  <BulletList> 
  <li> <strong>To Provide and Maintain our App:</strong> Ensuring the app functions as intended and providing customer support.</li>
  <li> <strong>To Improve and Personalise the App:</strong> Understanding and analysing how you use our app to enhance user experience and develop new features.</li>
  <li> <strong>To Communicate with You:</strong> Responding to your comments, questions, and requests, and providing updates and information about the app.</li>
</BulletList>   

<SubHeading id="informationdisclosure">3. Disclosure of Your Information</SubHeading>
  <Paragraph>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</Paragraph>
  <BulletList> 
  <li> <strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others.</li>
  <li> <strong>Service Providers:</strong> We may share your information with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work.</li>
</BulletList>

<SubHeading id="datastoragesecurity">4. Data Storage and Security</SubHeading>
  <Paragraph>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</Paragraph>

  <SubHeading id="dataprotectionrights">5. Your Data Protection Rights</SubHeading>
  <Paragraph>Depending on your location, you may have the following rights regarding your personal information:</Paragraph>
  <BulletList> 
  <li> <strong>Access:</strong> Request access to the personal information we have about you.</li>
  <li> <strong>Correction:</strong> Request that we correct or update any personal information you believe is inaccurate.</li>
  <li> <strong>Deletion:</strong> Request that we delete your personal information under certain conditions.</li>
  <li> <strong>Objection:</strong> Object to our processing of your personal information.</li>
  <li> <strong>Restriction:</strong> Request that we restrict the processing of your personal information.</li>
  <li> <strong>Portability:</strong> Request the transfer of your personal information to another organization, or directly to you, under certain conditions.</li>
</BulletList>
  <Paragraph>To exercise any of these rights, please contact us at shafd1231@gmail.com.</Paragraph>

  <SubHeading id="childrensprivacy">6. Children's Privacy</SubHeading>
  <Paragraph>Our app is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under age 13 without verification of parental consent, we will take steps to remove that information from our servers.</Paragraph>

  <SubHeading id="changestoprivacypolicy">7. Changes to This Privacy Policy</SubHeading>
  <Paragraph>We may update this privacy policy from time to time in order to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</Paragraph>

  <SubHeading id="contactus">8. Contact Us</SubHeading>
  <Paragraph>If you have any questions or concerns about this privacy policy, please contact us at: Email: shafd1231@gmail.com</Paragraph>

      </SectionWrapper>
    </>
  );
}

/*
Create your privacy policy here. Use <Heading> tags to create headings. Use <SubHeading> to create a subheading. Use <Paragraph> to create a paragraph.
*/
